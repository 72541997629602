@mixin Link
{
  text-decoration: none;

  @include hoverActive
  {
    text-decoration: underline;
  }
}

.Link
{
  @include Link;
}

@mixin Container
{
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @include bpgte(sm)
  {
    width: 768px;
  }

  @include bpgte(md)
  {
    width: 992px;
  }

  @include bpgte(lg)
  {
    width: 1170px;
  }
}

.Container
{
  @include Container;
}
