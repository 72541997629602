$peach: #f47721;
$lime: #9ac53e;

.Bday
{
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding-top: $gutter;
  padding-bottom: $gutter;
  color: $white;
  background-color: $peach;
  font-size: 20px;
  transition: 2s linear background-color;

  &--gifts
  {
    background-color: $lime;
  }

  &-passWrap
  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    background-color: $white;
  }

  &-passPrompt
  {
    background-color: $peach;
    width: 100%;
    font-size: 16px;
    text-align: center;
  }

  &-pass
  {
    background-color: rgba(244, 119, 33, 0.3);
    color: $lime;
    font-weight: bold;
    outline: 0;
    padding: $gutter / 2;
    width: 300px;
  }

  &-heading
  {
    text-align: center;
    padding-bottom: $gutter-mini;
    font-weight: bold;
    font-size: 64px;
    text-shadow: 3px 3px 0 #5bbfeb, 6px 6px 0 #fae64b, 9px 9px 0 #9ac53e, 12px 12px 0 #f47721, 15px 15px 0 #023d57;
  }

  &-subtexts
  {
    text-align: center;
  }

  &-subtext
  {
    padding-top: $gutter;
  }

  &-imageSection
  {
    margin-top: $gutter * 2;
    padding-top: $gutter * 2;
    position: relative;

    &::before
    {
      content: '';
      height: 1px;
      width: $gutter * 8;
      background-color: $white;
      position: absolute;
      top: 0;
      left: calc(50% - #{$gutter * 4})
    }
  }

  &-imageHeading
  {
    font-size: 32px;
    text-align: center;
    padding-bottom: $gutter;
  }

  &-images
  {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-image
  {
    height: 400px;
    margin: $gutter;
  }

  &-showGiftsWrap
  {
    padding: $gutter 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-showGiftsButton
  {
    margin-top: $gutter;
    color: $peach;
    background-color: $white;
    border: 1px solid $white;
    padding: 0 $gutter;
    height: $gutter * 3;
    border-radius: $gutter * 2;
    transition: $transition-fast all;

    &:hover
    {
      background-color: transparent;
      color: $white;
    }
  }

  &-gifts
  {
    margin-top: $gutter * 2;
  }

  &-gift
  {
    display: flex;
    justify-content: center;

    &--2
    {
      margin-top: $gutter * 4;
    }
  }

  &-giftButton
  {
    min-width: 215px;
    transition: 1s ease-in-out transform;

    &--left
    {
      transform: rotate(-10deg);
    }

    &--right
    {
      transform: rotate(10deg);
    }
  }

  &-giftIconWrap
  {
    min-height: 215px;
    position: relative;
  }

  &-giftIcon
  {
    height: 200px;
    position: absolute;
    top: 0px;
    left: 0px;

    &:nth-child(5)
    {
      top: 3px;
      left: 3px;
    }

    &:nth-child(4)
    {
      top: 6px;
      left: 6px;
    }

    &:nth-child(3)
    {
      top: 9px;
      left: 9px;
    }

    &:nth-child(2)
    {
      top: 12px;
      left: 12px;
    }

    &:nth-child(1)
    {
      top: 15px;
      left: 15px;
    }
  }

  &-openedGift
  {
    display: flex;
    align-items: center;

    &.Hidden
    {
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }

  &-giftContent
  {
    flex: 2;
    padding-right: $gutter * 2;

    .Bday-gift--2 &
    {
      padding-right: 0;
      padding-left: $gutter * 2;
    }
  }

  &-giftImages
  {
    flex: 1;
  }

  &-giftImage:not(:first-child)
  {
    margin-top: $gutter-mini;
  }

  &-giftTitle
  {
    font-size: 32px;
  }

  &-giftText
  {
    margin-top: $gutter-mini * 2;

    a
    {
      text-decoration: underline;

      &:hover
      {
        text-decoration: none;
      }
    }
  }
}

.Hidden
{
  pointer-events: none;
  opacity: 0;
  transition: $transition-slow opacity;
}

.Shown
{
  opacity: 1;
  transition: $transition-slow opacity;
}

@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
  }
}
@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
  }
}
@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
  }
}
.confetti-container {
  perspective: 1000px;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0;
}
.confetti--animation-slow {
  animation: confetti-slow 6s linear 1 forwards;
}
.confetti--animation-medium {
  animation: confetti-medium 5s linear 1 forwards;
}
.confetti--animation-fast {
  animation: confetti-fast 4s linear 1 forwards;
}
